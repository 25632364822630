import type { ReactNode } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useTrialBanner } from "@/react/components/Layout/TrialBanner/useTrialBanner";
import { useSmOrMdOrLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { useIsEitherOptInOrPreviewBarVisible } from "@circle-react/components/NewVersionOptIn/useIsEitherOptInOrPreviewBarVisible";
import { useRailbar } from "@circle-react/hooks/chatsV2";
import { useDmsV3Enabled } from "@circle-react/hooks/useDmsV3Enabled";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useMasqueradeEnabledAndActive } from "@circle-react/hooks/useMasqueradeEnabledAndActive";
import { useLocalStorage } from "@circle-react/hooks/utils/useLocalStorage";
import { SkeletonLoader } from "@circle-react-uikit/SkeletonLoader";
import { Content } from "./Content";
import { Header } from "./Header";
import { RailbarDragger } from "./RailbarDragger";

export const HEADER_HEIGHT = 64;
export const HEADER_HEIGHT_SM = 48;
export const BOTTOM_NAV_HEIGHT = 56;

export interface RailProps {
  isBelowHeader?: boolean;
  noBorderLeft?: boolean;
  isLoading?: boolean;
  loaderVariant?: string;
  children?: ReactNode;
  className?: string;
}

const RAILBAR_WIDTH_LOCAL_STORAGE_KEY = "circle-railbar-width";

const Rail = ({
  isBelowHeader = false,
  noBorderLeft = false,
  isLoading,
  loaderVariant = "rail-bar",
  children,
  className = "",
}: RailProps) => {
  const { isTrialBannerExists } = useTrialBanner();
  const isSmOrMdScreen = useSmOrMdOrLgScreenMediaQuery();
  const { isMessagingModalOpen } = useDmsV3Enabled();
  const { isLiveStream, isCourseSpace, isDrawer } = useRailbar();
  const isMasqueradingEnabledAndActive = useMasqueradeEnabledAndActive();
  const { shouldShowTrialBanner } = useTrialBanner();
  const isEitherOptInOrPreviewBarVisible =
    useIsEitherOptInOrPreviewBarVisible();
  const { isV3Enabled } = useIsV3();
  const sidebarRef = useRef<HTMLInputElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const { setValue, getValue } = useLocalStorage<number>(
    RAILBAR_WIDTH_LOCAL_STORAGE_KEY,
  );
  const [railbarWidth, setRailbarWidth] = useState<number>(getValue() || 360);

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    mouseMoveEvent => {
      if (isResizing) {
        const screenWidth =
          sidebarRef.current?.getBoundingClientRect().right || 0;
        const sidebarWidth = Math.abs(mouseMoveEvent.clientX - screenWidth);
        if (sidebarWidth > 332 && sidebarWidth < 720) {
          setRailbarWidth(sidebarWidth);
          setValue(sidebarWidth);
        }
      }
    },
    [isResizing],
  );

  useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  const isCourseMemberView = () => {
    const helloBar = document.getElementById("lesson-hello-bar");
    return isCourseSpace && isEmpty(helloBar);
  };

  const hasHeaderV2Styles = {
    "lg:top-12": true,
    "lg:!top-0":
      isMessagingModalOpen || isLiveStream || isDrawer || isCourseMemberView(),
    "lg:top-24":
      (isTrialBannerExists && !isCourseSpace) || isMasqueradingEnabledAndActive,
    "lg:top-36":
      isTrialBannerExists && !isCourseSpace && isMasqueradingEnabledAndActive,
    "lg:top-16": isBelowHeader,
    "border-l-0": noBorderLeft,
    "z-50 !h-full !w-full": isSmOrMdScreen,
    "lg:top-48":
      !isCourseSpace &&
      shouldShowTrialBanner &&
      isMasqueradingEnabledAndActive &&
      isEitherOptInOrPreviewBarVisible,
  };

  const hasHeaderV3Styles = {
    "lg:top-16": true,
    "lg:!top-0":
      isMessagingModalOpen || isLiveStream || isDrawer || isCourseMemberView(),
    "lg:top-28":
      (isTrialBannerExists && !isCourseSpace) || isMasqueradingEnabledAndActive,
    "lg:top-40":
      isTrialBannerExists && !isCourseSpace && isMasqueradingEnabledAndActive,
    "lg:top-20": isBelowHeader,
    "border-l-0": noBorderLeft,
    "z-50 !h-full !w-full": isSmOrMdScreen,
  };

  const topStyles = isV3Enabled ? hasHeaderV3Styles : hasHeaderV2Styles;

  const shouldHideRaibarDragOption = isLiveStream || isCourseSpace;
  if (isLoading) {
    return <SkeletonLoader variant={loaderVariant} />;
  }

  return (
    <div
      className={classNames("flex h-full flex-row", className)}
      style={{ width: railbarWidth }}
    >
      {!shouldHideRaibarDragOption && (
        <RailbarDragger
          startResizing={startResizing}
          isMessagingModalOpen={isMessagingModalOpen}
          isTrialBannerExists={isTrialBannerExists}
          isV3Enabled={isV3Enabled}
          isEitherOptInOrPreviewBarVisible={isEitherOptInOrPreviewBarVisible}
        />
      )}
      <div
        ref={sidebarRef}
        id="rail-content"
        className={classNames(
          "bg-primary border-primary z-2 fixed right-0 top-0 h-full border-l opacity-100",
          topStyles,
          {
            "m-4 !h-[calc(100vh-32px)] !w-96 rounded-lg": isLiveStream,
            "!w-full": isDrawer,
          },
        )}
        style={{ width: railbarWidth }}
      >
        {children}
      </div>
    </div>
  );
};

Rail.Header = Header;
Rail.Content = Content;

export { Rail };
