import { t } from "i18n-js";
import { thirdPartyEmbeds } from "./thirdPartyEmbeds";
import type { Command, CommandItem, EditorAndRange } from "./types";

interface EmbedModalProps extends EditorAndRange {
  provider?: string;
}

interface FileUploadModalProps extends EditorAndRange {
  type?: string;
}

export interface GetMenuItemsProps {
  showEmbedModal: ({ editor, range, provider }: EmbedModalProps) => void;
  showImagePickerModal: ({ editor, range }: EditorAndRange) => void;
  showFileUploadModal: ({ editor, range, type }: FileUploadModalProps) => void;
  showGiphyPickerModal: ({ editor, range }: EditorAndRange) => void;
  showPollModal: ({ editor, range }: EditorAndRange) => void;
  openUpgradeModal: () => void;
  shouldShowCTASlashCommand: boolean;
  showCTASettingsModal: ({ editor, range }: EditorAndRange) => void;
  shouldShowPlanUpgradeModal: boolean;
}

export const getMenuItems = ({
  showEmbedModal,
  showImagePickerModal,
  showFileUploadModal,
  showGiphyPickerModal,
  showPollModal,
  showCTASettingsModal,
  shouldShowCTASlashCommand,
  openUpgradeModal,
  shouldShowPlanUpgradeModal,
}: GetMenuItemsProps): CommandItem[] => {
  const embedProviderCommands: Command[] = thirdPartyEmbeds.map(
    ({ name, title }) => ({
      name,
      title,
      icon: `16-editor-${name}`,
      command: ({ editor, range }) =>
        showEmbedModal({ editor, range, provider: title }),
    }),
  );
  const basicCommands: Command[] = [
    {
      name: "ai_copilot",
      title: t("tiptap.slash_commands.co_pilot"),
      icon: "20-community-ai",
      command: ({ editor, range }) => {
        if (shouldShowPlanUpgradeModal) {
          openUpgradeModal();
        } else {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            // @ts-expect-error - Needs to update the module declaration
            .addAiCopilotNode()
            .run();
        }
      },
    },
    {
      name: "paragraph",
      title: t("tiptap.slash_commands.paragraph"),
      icon: "16-editor-paragraph",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setNode("paragraph").run();
      },
    },
    {
      name: "h1",
      title: t("tiptap.slash_commands.heading_1"),
      icon: "16-heading1",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 1 })
          .run();
      },
    },
    {
      name: "h2",
      title: t("tiptap.slash_commands.heading_2"),
      icon: "16-editor-h2",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 2 })
          .run();
      },
    },
    {
      name: "h3",
      title: t("tiptap.slash_commands.heading_3"),
      icon: "16-editor-h3",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 3 })
          .run();
      },
    },
    {
      name: "ordered_list",
      title: t("tiptap.slash_commands.number_list"),
      icon: "16-editor-ol",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleOrderedList().run();
      },
    },
    {
      name: "bullet_list",
      title: t("tiptap.slash_commands.bullet_list"),
      icon: "16-editor-ul",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleBulletList().run();
      },
    },
    {
      name: "block-quote",
      title: t("tiptap.slash_commands.blockquote"),
      icon: "16-editor-quote",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleBlockquote().run();
      },
    },
    {
      name: "divider",
      title: t("tiptap.slash_commands.divider"),
      icon: "16-editor-divider",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setHorizontalRule().run();
      },
    },
    {
      name: "code",
      title: t("tiptap.slash_commands.code"),
      icon: "16-editor-code",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleCodeBlock().run();
      },
    },
    {
      name: "emoji",
      title: t("tiptap.slash_commands.emoji"),
      icon: "16-editor-emoji",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).insertContent(":").run();
      },
    },
    {
      name: "mention",
      title: t("tiptap.slash_commands.mention"),
      icon: "16-editor-mention",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).insertContent("@").run();
      },
    },
  ];

  if (shouldShowCTASlashCommand) {
    basicCommands.push({
      name: "cta",
      title: t("tiptap.slash_commands.cta"),
      icon: "16-link",
      command: showCTASettingsModal,
    });
  }
  const uploadCommands: Command[] = [
    {
      name: "poll",
      title: t("tiptap.slash_commands.poll"),
      icon: "16-editor-poll",
      command: showPollModal,
    },
    {
      name: "image",
      title: t("tiptap.slash_commands.image"),
      icon: "16-editor-image",
      command: showImagePickerModal,
    },
    {
      name: "file",
      title: t("tiptap.slash_commands.file"),
      icon: "16-editor-file",
      command: showFileUploadModal,
    },
    {
      name: "audio",
      title: t("tiptap.slash_commands.audio"),
      icon: "16-editor-audio",
      command: ({ editor, range }) =>
        showFileUploadModal({
          editor,
          range,
          type: "audio",
        }),
    },
    {
      name: "video",
      title: t("tiptap.slash_commands.video"),
      icon: "16-editor-video",
      command: ({ editor, range }) =>
        showFileUploadModal({
          editor,
          range,
          type: "video",
        }),
    },
    {
      name: "pdf",
      title: t("tiptap.slash_commands.pdf"),
      icon: "16-editor-pdf",
      command: ({ editor, range }) =>
        showFileUploadModal({
          editor,
          range,
          type: "pdf",
        }),
    },
    {
      name: "giphy",
      title: t("tiptap.slash_commands.giphy"),
      icon: "16-editor-giphy",
      command: showGiphyPickerModal,
    },
  ];

  const embedCommands: Command[] = [
    {
      name: "embed",
      title: t("tiptap.slash_commands.embed"),
      icon: "16-editor-embed",
      command: showEmbedModal,
    },
    ...embedProviderCommands,
  ];

  return [
    {
      name: t("tiptap.slash_commands.basic"),
      commands: basicCommands,
    },
    {
      name: t("tiptap.slash_commands.upload"),
      commands: uploadCommands,
    },
    {
      name: t("tiptap.slash_commands.embed"),
      commands: embedCommands,
    },
  ];
};
